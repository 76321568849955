<template>
  <div>
    <a-form-model
      :model="form"
      labelAlign="left"
      :label-col="{ span: 3 }"
      :wrapper-col="{ span: 5 }"
    >
      <a-form-model-item label="标题">
        <a-input v-model="form.title"> </a-input>
      </a-form-model-item>
      <a-form-model-item label="简介">
        <a-input v-model="form.brief"> </a-input>
      </a-form-model-item>
      <a-form-model-item label="是否公开[1:公开  0：不公开]">
        <a-input v-model="form.openFlg"> </a-input>
      </a-form-model-item>
      <a-form-model-item label="排序">
        <a-input v-model="form.sort"> </a-input>
      </a-form-model-item>
      <a-form-model-item label="内容" :wrapper-col="{ span: 15 }">
        <Ueditor :content="form.content" @editorFn="editorFn" />
      </a-form-model-item>
    </a-form-model>
    <footer>
      <a-button class="mr40" @click="() => $router.go(-1)"> 返回 </a-button>
      <a-button
        type="primary"
        @click="keepClick"
        :loading="keepLoading"
        :disabled="keepLoading"
        >保存</a-button
      >
    </footer>
  </div>
</template>

<script>
import {
  FannieBlogsDetailApi,
  FannieBlogsUpdApi,
  FannieBlogsAddApi,
} from "@/request/api/home";
import Ueditor from "@/components/ueditor/ueditor";
import { message } from "ant-design-vue";
export default {
  components: { Ueditor },
  created() {
    this.id = this.$route.params.id;
    if (this.id != 0) {
      FannieBlogsDetailApi({ id: this.id }).then(({ code, data }) => {
        if (code == 200) {
          this.form = data;
        }
      });
    }
  },
  data() {
    return {
      id: "",
      form: {},
      keepLoading: false,
    };
  },
  methods: {
    // 富文本的函数
    editorFn(value) {
      this.form.content = value;
    },
    keepClick() {
      this.keepLoading = true;
      setTimeout(() => {
        this.keepLoading = false;
      }, 3000);
      if (this.id != 0) {
        FannieBlogsUpdApi(this.form).then(({ code, data }) => {
          if (code == 200) {
            message.success("编辑成功");
            this.$router.push("/");
          }
        });
      } else {
        FannieBlogsAddApi(this.form).then(({ code, data }) => {
          if (code == 200) {
            message.success("新增成功");
            this.$router.push("/");
          }
        });
      }
    },
  },
};
</script>
 
<style lang="less" scoped>
footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  right: 0;
  height: 56px;
  line-height: 56px;
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.03);
  background: #fff;
  border-top: 1px solid #e8e8e8;
  padding: 0 24px;
  z-index: 1000;
}
.mr40 {
  margin-right: 40px;
}
</style>